// Run that command whenever you add a new controller or create them with
// ./bin/rails generate stimulus controllerName

import { application } from "./application"

import ApiTokensController from "./api_tokens_controller";
import BillController from "./bill_controller";
import BillRowController from "./bill_row_controller";
import MapBoxController from "./map_box_controller";
import RoomController from "./room_controller";
import FavouriteFacilityController from "./favourite_facility_controller";
import PopoverController from "./popover_controller";
import VenueUsersController from "./venue_users_controller";
import MultiMessagesButtonController from "controllers/multi_message_button_controller";
import CognitaVenueFieldsController from "controllers/cognita_venue_fields_controller";
import CustomMessageSignatureController from "controllers/custom_message_signature_controller";
import TagsInputController from "controllers/tags_input_controller";
import EditAccountPasswordController from "controllers/edit_account_password_controller";
import OrderCancellationRequestController from "controllers/order_cancellation_request_controller";
import LinkVenuesFacilitiesController from "controllers/link_venues_facilities_controller";
import NeoTableController from "controllers/neo_table_controller";
import SoftDeletedFacilityController from "controllers/admin/soft_deleted_facility";
import FlashController from "controllers/flash_controller";
import OrderMenuButtonController from "controllers/order_menu_button_controller";
import CustomerCustomOptionsController from "controllers/admin/customer_custom_options_controller";
import CustomerVenueLinkingsController from "controllers/admin/customer_venue_linkings_controller";
import VenueGroupsController from "controllers/admin/venue_groups_controller";
import AttachmentsController from "controllers/admin/attachments_controller";
import BillVersionsController from "controllers/bill_versions_controller";
import BillButtonsController from "controllers/bill_buttons_controller";
import Checkouts__VenueQuestionsController from "./checkouts/venue_questions_controller";
import NeoModalController from "./neo_modal_controller";
import NeoButtonController from "./neo_button_controller";
import FileUploaderController from "./file_uploader_controller";
import PendingModificationController from "controllers/pending_modification_controller";
import IncomeOptionFormController from "controllers/admin/income_option_form_controller";
import PaymentCardController from "controllers/payment_card_controller";
import CheckoutPaymentController from "controllers/checkout_payment_controller";
import PaymentFormController from "controllers/payment_form_controller";
import RetryFailedPaymentController from "controllers/retry_failed_payment_controller";
import CasualPaymentController from "controllers/casual_payment_controller";
import CalendarScrollerController from "controllers/calendar_scroller_controller";
import DatepickerController from "./datepicker_controller";
import FileManagerController from "./file_manager_controller";
import ConversationButtons from "controllers/conversation_buttons_controller";
import LinkifyController from "controllers/linkify_controller";
import FileUploaderValidator from "controllers/file_uploader_validator_controller";
import FacilityController from "controllers/facility_controller";
import ReactComponentController from "controllers/react_component_controller";
import InfoPageController from "controllers/info_page_controller";
import OnlineFacilityController from "controllers/online_facility_controller";
import OnlineMembershipPlanController from "controllers/online_membership_plan_controller";
import NewSpecialDateController from "./admin/new_special_date_controller";
import NestedForm from 'stimulus-rails-nested-form';
import ToggleInputsController from "./toggle_inputs_controller";
import FuturePricesModalController from "./admin/future_prices_modal_controller";
import SessionStorageController from "./session_storage_controller";
import ExtraFormController from "./admin/extra_form_controller";
import ExtrasTableController from "controllers/extras_table_controller";
import SignOutController from "./sign_out_controller";
import CheckboxBlockController from "./checkbox_block_controller";
import PaymentScheduleOverrideController from "./payment_schedule_override_controller";
import VenueController from "./venue_controller";
import InboxUnreadCountController from "./inbox_unread_count_controller";
import OnlineMembershipCancellation from "controllers/online_membership_cancellation";
import FileManagerFiltersController from "./file_manager_filters_controller";

application.register("api_tokens", ApiTokensController)
application.register("bill", BillController)
application.register("bill_row", BillRowController)
application.register("map_box", MapBoxController)
application.register("room", RoomController)
application.register("fovourite_facility", FavouriteFacilityController)
application.register("popover", PopoverController)
application.register("venue_users", VenueUsersController)
application.register("multi_message_button", MultiMessagesButtonController)
application.register("cognita_venue_fields", CognitaVenueFieldsController)
application.register("auto_message_signature", CustomMessageSignatureController)
application.register("tags_input", TagsInputController)
application.register("edit_account_password", EditAccountPasswordController)
application.register("order_cancellation_request", OrderCancellationRequestController)
application.register("link_venues_facilities", LinkVenuesFacilitiesController)
application.register("neo_table", NeoTableController)
application.register("soft_deleted_facility", SoftDeletedFacilityController)
application.register("order_menu_button", OrderMenuButtonController)
application.register("customer_custom_options", CustomerCustomOptionsController)
application.register("customer_venue_linkings", CustomerVenueLinkingsController)
application.register("flash", FlashController)
application.register("venue_groups", VenueGroupsController)
application.register("attachments", AttachmentsController)
application.register("bill_versions", BillVersionsController)
application.register("bill_buttons", BillButtonsController)
application.register("checkouts--venue_questions", Checkouts__VenueQuestionsController)
application.register("neo_button", NeoButtonController)
application.register("neo_modal", NeoModalController)
application.register("file_uploader", FileUploaderController)
application.register("pending_modification", PendingModificationController)
application.register("income_option_form", IncomeOptionFormController)
application.register("payment_card", PaymentCardController)
application.register("checkout_payment", CheckoutPaymentController)
application.register("payment_form", PaymentFormController)
application.register("retry_failed_payment", RetryFailedPaymentController)
application.register("casual_payment", CasualPaymentController)
application.register("calendar_scroller", CalendarScrollerController)
application.register("datepicker", DatepickerController)
application.register("file_manager", FileManagerController)
application.register("conversation_buttons", ConversationButtons)
application.register("linkify", LinkifyController)
application.register("file_uploader_validator", FileUploaderValidator)
application.register("facility", FacilityController)
application.register("react_component", ReactComponentController)
application.register("info_page", InfoPageController)
application.register("online_facility", OnlineFacilityController)
application.register("online_membership_plan", OnlineMembershipPlanController)
application.register("sign_out", SignOutController);
application.register("checkbox_block", CheckboxBlockController);
application.register("payment_schedule_override", PaymentScheduleOverrideController);
application.register("new_special_date", NewSpecialDateController)
application.register('nested-form', NestedForm)
application.register("sign_out", SignOutController)
application.register("toggle_inputs", ToggleInputsController)
application.register("future_prices_modal", FuturePricesModalController)
application.register("session_storage", SessionStorageController)
application.register("extra_form", ExtraFormController)
application.register("extras_table", ExtrasTableController)
application.register("venue", VenueController)
application.register("online_membership_cancellation", OnlineMembershipCancellation)
application.register("inbox_unread_count", InboxUnreadCountController)
application.register("file_manager_filters", FileManagerFiltersController)


import BookingCustomMessagesController from "./admin/booking_custom_messages_controller";
application.register("booking_custom_messages", BookingCustomMessagesController)

import OnlineMembershipPackageController from "controllers/online_membership_package_controller";
application.register("online_membership_package", OnlineMembershipPackageController);

import OnlineMemberMultiMessageButtonController from "controllers/online_member_multi_message_button_controller";
application.register("online_member_multi_message_button", OnlineMemberMultiMessageButtonController)

import ModalBillSendController from "./modals/bill_send_controller";
application.register('modal--bill_send', ModalBillSendController);

import BillCountdownController from "./bill_countdown_controller";
application.register('bill_countdown', BillCountdownController);

import BookingOptionController from "./booking_option_controller";
application.register('booking_option', BookingOptionController);

import MessagesCounterController from "./messages_counter_controller";
application.register("messages_counter", MessagesCounterController)

import ModificationController from "./modification_controller";
application.register("modification", ModificationController)

import OrderModalController from "./order_modal_controller";
application.register("order_modal", OrderModalController)

import OrdersTableController from "./orders_table";
application.register("orders_table", OrdersTableController)

import OrderApprovalController from "./order_approval_controller";
application.register("order_approval", OrderApprovalController)

import PostgresQueryController from "./postgres_query_controller";
application.register("postgres-query", PostgresQueryController)

import ConversationRemoveThread from "./conversation_remove_thread_controller";
application.register("conversation_remove_thread", ConversationRemoveThread)

import DynamicPopoverThread from "./dynamic_popover_controller";
application.register("dynamic_popover", DynamicPopoverThread)


// BILLS
import BillApproval from "./bill/approval";
application.register("bill--approval", BillApproval)

import OrderVenueNoteController from "./order_venue_note_controller";
application.register("order_venue_note", OrderVenueNoteController)
