import SmartyForm from "../src/nextgen/helpers/smarty_form";
import axios from "axios";
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modal = $('#admin_orders_modal');
  }

  show_modal(e) {
    e.preventDefault();
    this.showVenueNoteModal(e.target.getAttribute('href'));
  }

  showVenueNoteModal(link) {
    axios.get(link).then((response) => {
      this.modal.find('.modal-content').empty().append(response.data);
      this.modal.modal('show');
    }).catch(() => {
      Helper.flash_message('error', 'Something went wrong');
    })
  };

  saveVenueNote(e) {
    e.preventDefault();

    const { orderId } = e.target.dataset;
    const form = e.target.closest('form');
    const formData = new FormData(form);
    const action = form.getAttribute('action');

    axios.post(action, formData).then(() => {
      this.modal.find('.modal-content').empty();
      this.modal.modal('hide');
      axios.get(action + '/info').then((response) => {
        const infoRow = document.getElementById(`order-row-note-${orderId}`)
        if (infoRow) infoRow.remove();

        if (response.status !== 204) {
          const row = document.getElementById(`order-row-${orderId}`);
          if (row) {
            const container = row.parentElement;
            const tempContainer = document.createElement('table');
            tempContainer.innerHTML = response.data;
            const newElement = tempContainer.querySelector('tr');
            container.insertBefore(newElement, row);
          }
        }
      }).catch(() => Helper.flash_message('error', 'Something went wrong'))
    }).catch((e) => {
      Helper.flash_message('error', 'Something went wrong');
    })
  };
}
